.buttonComponet {
    background: rgb(144, 152, 158);
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 190%;
}

.buttonComponetHover {
    background: rgb(0, 0, 0);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 190%;
}